import React, { useContext } from "react";
import Slider from "react-slick";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import IconsGen from "../icons_generator/IconsGen";
import AxiosController from "../authentication/AxiosController";
import { notification } from "antd";
import { UserContext } from "../contexts/UserContext";
function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className={"slick-arrow"} onClick={onClick} style={nextArrowStyles}>
      <IconsGen icon_name={"nextArrow"} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className={"slick-arrow"} onClick={onClick} style={prevArrowStyles}>
      <IconsGen icon_name={"prevArrow"} />
    </div>
  );
}
// function trimStart(text, prefix) {
//   if (text.startsWith(prefix)) {
//     return text.substring(prefix.length).trimStart();
//   }
//   return text;
// }

const ProductsSlider = ({ row, altName }) => {
  const { userData } = useContext(UserContext);
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const [api, contextHolder] = notification.useNotification();
  const Context = React.createContext({
    name: _("panel.AddedToCart"),
  });
  const openNotification = (placement, status) => {
    api.success({
      style: { direction: lang == "ar" ? "rtl" : "ltr" },
      description: `${status}!`,
      placement,
    });
  };

  const Navigate = useNavigate();
  const token = localStorage.getItem("token");
  const settings = {
    infinite: row.length > 4 ? true : false,
    swipe: row.length > 4 ? true : false,
    slidesToShow: 5,
    slidesToScroll: row.length > 4 ? 4 : row.length,
    nextArrow: row.length > 5 ? <SampleNextArrow /> : "",
    prevArrow: row.length > 5 ? <SamplePrevArrow /> : "",
    autoplay: false,
    autoplaySpeed: 1000,
    speed: 200,
    rtl: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          nextArrow: "",
          prevArrow: "",
        },
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: "",
          prevArrow: "",
        },
      },
    ],
  };
  const addToCart = (prodcut_id) => {
    AxiosController.post(
      `api/${slug}/${lang}/add-to-cart/${prodcut_id}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        document.getElementById("ecomboCart").click();
      })
      .catch((e) => {
        Navigate(`/${slug}/${lang}/product/${prodcut_id}`);
      });
  };
  const addToFav = (prodcut_id) => {
    AxiosController.post(
      `api/${slug}/${lang}/add-to-fav/${prodcut_id}`,
      {},
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        openNotification(
          lang == "ar" ? "topRight" : "topLeft",
          res.data.success.fav_added
        );
      })
      .catch((e) => {
        Navigate(`/${slug}/${lang}/product/${prodcut_id}`);
      });
  };
  return (
    <Container className="mt-4 slider-padding ">
      {contextHolder}

      <Row dir={lang === "en" ? "rtl" : ""}>
        <Col className="d-flex flex-row-reverse justify-content-between align-items-center">
          <h2
            style={{
              color: "rgba(23, 43, 77, 1)",
              textTransform: "capitalize",
            }}
            className="fs-3"
          >
            {row[0] &&
              (lang === "en" ? row[0].english_name : row[0].arabic_name)}
          </h2>
          {row[0]?.cat_id && (
            <Link
              style={{
                color: "rgba(89, 198, 201, 1)",
                border: "1px solid rgba(89, 198, 201, 1)",
                background: "transparent",
                textDecoration: "none",
              }}
              className="p-2 rounded-3 cat-link"
              to={
                row[0]?.cat_id && `/${slug}/${lang}/category/${row[0].cat_id}`
              }
            >
              {_("home.seemore")}
            </Link>
          )}
        </Col>
      </Row>
      <Row>
        <Slider {...settings} className="mt-4" dir="ltr">
          {row.map((product) => {
            if (product.price >= 0) {
              return (
                <div className="p-2" id={product.id}>
                  <Card
                    className="pt-3 px-2 mr-2"
                    style={{
                      flexGrow: 1,
                      paddingRight: "100px",
                      minHeight: "100%",
                      position: "relative"
                    }}
                    dir="rtl"
                  >
                     {product["is_product_discount"] && (
                    <div className="best-offer">
                      <span className="right"></span>عروض
                      <span className="left"></span>
                    </div>
                  )} 
                    <NavLink to={`/${slug}/${lang}/product/${product.id}`}>
                      <Card.Img
                        src={product.thumbnail}
                        className="card-img-top px-1"
                        style={{ borderRadius: "10px", height: "200px" }}
                      />
                    </NavLink>
                    <Card.Body className="px-1">
                      <Card.Title className="d-flex flex-column flex-sm-column flex-md-row-reverse gap-2 justify-content-between align-items-center">
                        <div className="rating d-flex align-items-center justify-content-between flex-row-reverse">
                          <IconsGen icon_name="star" />
                          <p style={{ margin: 0 }}>4.5</p>
                        </div>
                        <p
                          className="title"
                          style={{ margin: 0, fontSize: "1.2rem" }}
                        >
                          {product.title
                            ? product.title.slice(0, 12) + "..."
                            : ""}
                        </p>
                      </Card.Title>
                      <Card.Text
                        style={{
                          color: "rgba(165, 165, 165, 1)",
                          fontSize: "14.5px",
                        }}
                      >
                        {}
                      </Card.Text>
                    </Card.Body>
                    <Card.Body
                      className={`px-1 py-1 d-flex ${
                        lang === "en" ? "flex-row-reverse" : ""
                      } justify-content-between gap-5`}
                    >
                      <p>{_("home.price")}</p>
                      <p>
                        {userData?.level_data &&
                        userData?.level_data?.commission ? (
                          <>
                            <del className="text-secondary">
                              {product.price}
                            </del>
                          </>
                        ) : (
                          product.price
                        )}{" "}
                        {altName
                          ? _(`panel.${altName}`)
                          : slug === "sa"
                          ? _(`panel.ريال سعودي`)
                          : _(`panel.درهم امارتي`)}
                      </p>
                    </Card.Body>
                    {userData?.level_data &&
                    userData?.level_data?.commission ? (
                      <Card.Body
                        className={`px-1 py-1 d-flex ${
                          lang === "en" ? "flex-row-reverse" : ""
                        } justify-content-between gap-5`}
                      >
                        <p>{_("home.levelPrice")}</p>
                        <p className="ecombo-secondary">
                          {product.price - userData?.level_data?.commission}{" "}
                          {altName
                            ? _(`panel.${altName}`)
                            : slug === "sa"
                            ? _(`panel.ريال سعودي`)
                            : _(`panel.درهم امارتي`)}
                        </p>
                      </Card.Body>
                    ) : (
                      ""
                    )}

                    <Card.Body
                      className={`px-1 py-1 pb-3 d-flex flex-column align-items-center flex-sm-column ${
                        lang === "en" ? "flex-md-row-reverse" : "flex-md-row"
                      } justify-content-between gap-2`}
                    >
                      <span
                        className="d-inline-block"
                        onClick={() => addToCart(product.id)}
                        id={product.id}
                      >
                        <button style={buttonStyles} className="addtocart">
                          {_("home.addtocart")}
                        </button>
                      </span>
                      <div
                        className={` ${
                          product.is_fav === "true" ? true : false
                        }`}
                        onClick={(e) => {
                          addToFav(product.id);
                          e.target.parentNode.classList.toggle("active");
                        }}
                      >
                        <IconsGen
                          icon_name={"heartCart"}
                          active={product.is_fav === "true" ? true : false}
                        />
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              );
            }
          })}
        </Slider>
      </Row>
    </Container>
  );
};

const nextArrowStyles = {
  background: "rgb(89, 198, 201)",
  display: "block",
  position: "absolute",
  right: "-30px",
  top: "50%",
  transform: "translateY(-50%)",
  borderRadius: "100%",
};
const buttonStyles = {
  border: "none",
  padding: ".5rem .76rem",
  borderRadius: "7px",
};
const prevArrowStyles = {
  background: "rgb(89, 198, 201)",
  display: "block",
  position: "absolute",
  left: "-30px",
  top: "50%",
  transform: "translateY(-50%)",
  borderRadius: "100%",
};

export default ProductsSlider;
