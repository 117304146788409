import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AxiosController from "../../../authentication/AxiosController";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Button, Form, Row, Col, Pagination } from "react-bootstrap";
import { Loader } from "@mantine/core";
import IconsGen from "../../../icons_generator/IconsGen";

export const AffiliateSuggestionAdmin = () => {
  const [_] = useTranslation("global");
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");

  const [loading, setLoading] = useState(false);
  const [allData, setAllData] = useState([]);
  const [searchVisible, setSearchVisible] = useState(false);
  const [paginationData, setPaginationData] = useState({
    currentPage: 1,
    totalPages: 1,
  });

  const [searchQuery, setSearchQuery] = useState({
    page: 1,
    name: "",
    description: "",
    expected_quantity: "",
  });

  const [formValues, setFormValues] = useState({
    name: "",
    description: "",
    expected_quantity: "",
  });

  const box_head_dir = lang === "en" ? "ltr" : "rtl";

  const fetchSuggestions = async (query) => {
    setLoading(true);
    try {
      const response = await AxiosController.get(
        `api/${slug}/${lang}/products-suggestions`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: query,
        }
      );
      setAllData(response?.data?.data || []);
      setPaginationData({
        currentPage: response?.data?.meta?.current_page || 1,
        totalPages: response?.data?.meta?.total_pages || 1,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchQuery({
      ...formValues,
      page: 1, // Reset to the first page on a new search
    });
  };

  const handleClear = () => {
    setFormValues({
      name: "",
      description: "",
      expected_quantity: "",
    });
    setSearchQuery({
      page: 1,
      name: "",
      description: "",
      expected_quantity: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePageChange = (newPage) => {
    setSearchQuery((prev) => ({
      ...prev,
      page: newPage,
    }));
  };

  useEffect(() => {
    fetchSuggestions(searchQuery);
  }, [searchQuery]);

  return (
    <div className="flex-grow-1 stats-component">
      <Helmet>
        <title>{_("panel.AffiliateSuggestions")}</title>
      </Helmet>
      <div className="mb-4">
        <Button
          className="fs-5 px-5 w-25"
          style={{
            backgroundColor: "rgb(89, 198, 201)",
            border: "none",
            borderRadius: "7px",
          }}
          onClick={() => setSearchVisible((prev) => !prev)}
        >
          {_("panel.search")}
        </Button>
      </div>
      {searchVisible && (
        <Form onSubmit={handleSearch} className="mb-4">
          <Row className="g-3">
            <Col md={3}>
              <Form.Control
                name="name"
                placeholder={_("panel.productName2")}
                value={formValues.name}
                onChange={handleInputChange}
              />
            </Col>
            <Col md={3}>
              <Form.Control
                name="description"
                placeholder={_("panel.description")}
                value={formValues.description}
                onChange={handleInputChange}
              />
            </Col>
            <Col md={2}>
              <Form.Control
                name="expected_quantity"
                type="number"
                placeholder={_("panel.quantity")}
                value={formValues.expected_quantity}
                onChange={handleInputChange}
              />
            </Col>
            <Col md={2}>
              <Button
                type="submit"
                className="w-100"
                style={{
                  backgroundColor: "rgb(89, 198, 201)",
                  border: "none",
                  borderRadius: "7px",
                }}
              >
                {_("panel.search")}
              </Button></Col>
              <Col md={2}>{" "}
              <Button
              className="w-100"
              style={{
                backgroundColor: "rgb(243, 168, 55)",
                border: "none",
                borderRadius: "7px",
              }}
                onClick={handleClear}
              >
                {_("panel.reset")}
              </Button>
            </Col>
          </Row>
        </Form>
      )}

      {loading ? (
        <div className="d-flex justify-content-center my-4">
          <Loader size="lg" />
        </div>
      ) : (
        <div className="reports-container my-3 d-flex flex-column gap-2 px-2 position-relative">
          <div className="reports-header d-flex flex-column flex-md-row py-2 justify-content-between flex-wrap">
            <h3
              className="fs-2 mb-4"
              style={{ color: "rgba(89, 198, 201, 1)" }}
            >
              {_("panel.AffiliateSuggestions")}
            </h3>
          </div>
          <div className="row" dir={box_head_dir} style={{ height: "auto" }}>
            <div
              style={{ borderRadius: "40px" }}
              className={`table-responsive border my-3 d-flex ${
                lang === "en" ? "flex-row" : "flex-row-reversed"
              } align-items-center`}
            >
              <table
                className="table table-borderless"
                htmlFor="flexCheckDefault"
              >
                <thead>
                  <tr className="text-center">
                    {role === "admin" && <th>{_("panel.name")}</th>}
                    <th>{_("panel.productName2")}</th>
                    <th>{_("panel.quantity")}</th>
                    <th>{_("panel.enter")}</th>
                  </tr>
                </thead>
                <tbody>
                  {allData.length > 0 ? (
                    allData.map((item) => (
                      <tr className="text-center" key={item.id}>
                        {role === "admin" && (
                          <td>
                            <span className="fw-bold">
                              {item.user_id} <br />
                            </span>
                            {item.first_name} {item.last_name}
                          </td>
                        )}
                        <td>{item.name}</td>
                        <td>{item.expected_quantity}</td>
                        <td style={{ verticalAlign: "middle" }}>
                          <NavLink
                            to={`/${slug}/${lang}/panel/contact-us/affiliate-suggestion-admin/${item["id"]}`}
                          >
                            {<IconsGen icon_name={"enter"} />}
                          </NavLink>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="text-center">
                        {_("panel.noDataFound")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="d-flex justify-content-center my-3">
            <Pagination>
              <Pagination.Prev
                disabled={paginationData.currentPage === 1}
                onClick={() => handlePageChange(paginationData.currentPage - 1)}
              />
              {[...Array(paginationData.totalPages).keys()].map((page) => (
                <Pagination.Item
                  key={page + 1}
                  active={paginationData.currentPage === page + 1}
                  onClick={() => handlePageChange(page + 1)}
                >
                  {page + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next
                disabled={
                  paginationData.currentPage === paginationData.totalPages
                }
                onClick={() => handlePageChange(paginationData.currentPage + 1)}
              />
            </Pagination>
          </div>
        </div>
      )}
    </div>
  );
};
