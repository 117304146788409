import React, { useEffect, useState } from "react";
import "./Profile.css";
import AxiosController from "../../authentication/AxiosController";
import { useNavigate, useParams } from "react-router-dom";
import IconsGen from "../../icons_generator/IconsGen";
import Loader from "../../loader/Loader";
import { useTranslation } from "react-i18next";
import * as XLSX from "xlsx";

const ProfilePreview = () => {
  const { slug, lang, userId } = useParams();

  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const [userData, handleUserData] = useState({});
  const [refData, handleRefData] = useState({});
  const [userFullname, handleUserFullname] = useState("");
  const [userEmail, handleUserEmail] = useState("");
  const [country, handleCountry] = useState("");
  const [productSummary, handleProductSummary] = useState("");
  const [userNumber, handleUserNumber] = useState("");
  const [birthdate, handleBirthdate] = useState("");
  const [userAge, handleUserAge] = useState("");
  const [userCountry, handleUserCountry] = useState("");
  const [userImg, handleUserImg] = useState("");
  const [userRole, handleUserRole] = useState("");
  const [loader, handleLoader] = useState(false);
  const [_] = useTranslation("global");
  const Navigate = useNavigate();
  const box_head_dir = lang === "en" ? "left" : "right";

  const setUser = () => {
    handleLoader(true);
    if (role == "admin") {
      AxiosController.post(
        `api/${slug}/${lang}/users/get-user-token/${userId}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
        .then((res) => {
          const userId = res.data.user.id;
          const validIds = [3, 12768];
          
          if (validIds.includes(userId)) {
            localStorage.setItem("ecombo_affiliate", userId);
          } 
          
          localStorage.setItem("token", res.data.token); // Store the token in local storage
          localStorage.setItem("role", res.data.user.role); // Store the token in local storage
          localStorage.setItem("employee_type", res.data.user.employee_type); // Store the token in local storage
          localStorage.setItem("loginTime", new Date().getTime()); // Store the login timestamp in local storage
          Navigate(`/${slug}/${lang}`);
        })
        .catch((e) => {
          handleLoader(false);
        });
    } else {
      handleLoader(false);
    }
  };
  const marketer_orders_report = () => {
    AxiosController.get(
      `api/${slug}/${lang}/orders/affiliate-report/${userId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        let sum = 0;
        let data = res.data.map((order) => {
          sum += order["total_commissions"];
          return order;
        });
        data.push({
          quantity: 0,
          marketer_commissions: 0,
          total_commissions: sum,
          track: 0,
        });
        let worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "Affiliate-Report.xlsx");
      })
      .catch();
  };
  useEffect(() => {
    handleLoader(true);
    AxiosController.get(`api/${slug}/${lang}/admin/userdata/${userId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
         handleUserData(res.data);
        handleUserFullname(
          res.data["first_name"] + " " + res.data["last_name"]
        );
        handleUserEmail(res.data["email"]);
        handleCountry(res.data["country"]);
        handleUserNumber(res.data["phone_number"]);
        handleBirthdate(handleAge(res.data["birthdate"]));
        handleProductSummary(res.data["product_summary"]);
        handleUserImg(res.data["photo"]);
        handleLoader(false);
        handleUserRole(res.data["role"]);
      })
      .catch(() => {});
    AxiosController.get(`api/${slug}/${lang}/user/ref-data/${userId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        handleRefData(res.data);
      })
      .catch(() => {});
  }, [slug, lang, token]);

  function handleAge(birthdate) {
    const birthDate = new Date(birthdate);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }

    return age;
}

  return (
    <div
      className="profile-container d-flex flex-column px-4"
      style={{ position: "relative", flexGrow: 3 }}
    >
      <Loader active={loader} />
      <div
        className="ticket py-2 px-2"
        style={{ position: "absolute", left: "5%" }}
      >
        <p className="pt-1">{_("panel." + userRole)}</p>
      </div>
      <div className="header py-4">
        <div
          className={`upper d-flex ${
            lang === "en" ? "flex-row-reverse" : "flex-row"
          } gap-3`}
        >
          <div className="img-section col-4 align-self-center">
            <div className="img-container" style={{ position: "relative" }}>
              <img
                src={userImg}
                style={{ maxWidth: "100%", width: "90%", borderRadius: "7px" }}
                alt=""
              />
              <div
                className="upload p-2"
                style={{
                  position: "absolute",
                  backgroundColor: "rgba(243, 168, 55, 1)",
                  borderRadius: "50%",
                  left: "-2%",
                  bottom: "-5%",
                }}
              >
                <IconsGen icon_name={"camera"} />
              </div>
            </div>
          </div>
          <div className="user-info col-6 align-self-center">
            <div className=" info-group d-flex  flex-column">
              <div className="info-group d-flex gap-2">
                <span className="user-key">
                  <p>{_("panel.name")} : </p>
                </span>
                <span className="user-value">
                  <p>{userFullname}</p>
                </span>
              </div>
              <div className="info-group d-flex gap-2">
                <span className="user-key">
                  <p>{_("panel.accountnumber")} : </p>
                </span>
                <span className="user-value">
                  <p>{userData["id"]}</p>
                </span>
              </div>
            </div>
            <div className="info-group d-flex gap-2">
              <span className="user-key">
                <p>{_("panel.number")} :</p>
              </span>
              <span className="user-value">
                <p> {userNumber}</p>
              </span>
            </div>
            <div className="info-group d-flex gap-2">
              <span className="user-key">
                <p>{_("panel.email")} :</p>
              </span>
              <span className="user-value">
                <p>{userEmail}</p>
              </span>
            </div>
            {role == "admin" ? (
              <button
                className="btn"
                style={{ border: "1px solid #172B4D", color: "#172B4D" }}
                onClick={marketer_orders_report}
              >
                <IconsGen icon_name={"excel"} /> {_("panel.marketerReport")}
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="lower row gap-2 mt-4 px-3">
          <div
            className="box d-flex orangify flex-column col-4 col-md-2 py-3"
            style={{ textAlign: "center" }}
          >
            <div className="number">
              <p>
                {refData.total_accounts
                  ? refData.total_accounts.total_accounts
                  : 0}
              </p>
            </div>
            <h2>{_("panel.totalaccounts")}</h2>
          </div>
          <div
            className="box d-flex orangify flex-column col-4 col-md-2 py-3"
            style={{ textAlign: "center" }}
          >
            <div className="number">
              <p>
                {refData.total_orders ? refData.total_orders.orders_number : 0}
              </p>
            </div>
            <h2>{_("panel.totalorders")}</h2>
          </div>
          <div
            className="box d-flex flex-column col-4 col-md-2 py-3"
            style={{ textAlign: "center" }}
          >
            <div className="number">
              <p>
                {refData.total_orders_delivered
                  ? refData.total_orders_delivered.orders_number
                  : 0}
              </p>
            </div>
            <h2>{_("panel.deliveredorder")}</h2>
          </div>
          <div
            className="box d-flex flex-column col-4 col-md-2 py-3"
            style={{ textAlign: "center" }}
          >
            <div className="number">
              <p>
                {refData.total_orders_delivered
                  ? refData.total_orders_delivered.total_earnings?.toFixed(2) ||
                    0
                  : 0}
              </p>
            </div>
            <h2>{_("panel.profits")}</h2>
          </div>
        </div>
      </div>
      <hr />
      <div className="footer d-flex flex-column">
        <div
          className="header px-3"
          style={{ textAlign: box_head_dir }}
          dir={box_head_dir}
        >
          <h2>{_("panel.profileInfo")}</h2>
        </div>
        <div className="inputs-container" style={{ textAlign: box_head_dir }}>
          <div className="inputs-group d-flex flex-column flex-md-row  gap-5 px-3 my-4">
            <div className="d-flex flex-column">
              <label htmlFor="exampleInputEmail1" className="form-label">
                {_("panel.name")}
              </label>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                value={userFullname}
                aria-describedby="emailHelp"
                onChange={(e) => handleUserFullname(e.target.value)}
              />
            </div>
            <div className="d-flex flex-column">
              <label htmlFor="exampleInputEmail1" className="form-label">
                {_("panel.email")}
              </label>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                value={userEmail}
                aria-describedby="emailHelp"
                onChange={(e) => handleUserEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="inputs-group d-flex flex-column flex-md-row  gap-5 px-3 my-4">
            <div className="d-flex flex-column">
              <label htmlFor="exampleInputEmail1" className="form-label">
                {_("panel.number")}
              </label>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                value={userNumber}
                aria-describedby="emailHelp"
                onChange={(e) => handleUserNumber(e.target.value)}
              />
            </div>
            <div className="d-flex flex-column">
              <label htmlFor="exampleInputEmail1" className="form-label">
                {_("panel.age")}
              </label>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                value={birthdate}
                aria-describedby="emailHelp"
              />
            </div>
          </div>

          <div className="inputs-group d-flex flex-column flex-md-row  gap-5 px-3 my-4">
            <div className="d-flex flex-column">
              <label htmlFor="exampleInputEmail1" className="form-label">
                {_("panel.country")}
              </label>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                value={country} 
                onChange={(e) => handleCountry(e.target.value)}
              />
            </div>

            {productSummary&&<>
              <div className="d-flex flex-column">
              <label htmlFor="exampleInputProductSummary" className="form-label">
                {_("panel.productsSummary")}
              </label>
              <input
                type="text"
                className="form-control"
                id="exampleInputProductSummary"
                value={productSummary} 
                onChange={(e) => handleProductSummary(e.target.value)}
              />
            </div></>}
       
          </div>
        </div>
        {role == "admin" ? (
          <>
            <div className="header px-3" style={{ textAlign: box_head_dir }}>
              <h2>{_("panel.permissions")}</h2>
            </div>
            <div className="perm-container mt-3 row gap-2 pb-3">
              <div className="col-md-3 col-12 flex-grow-1 d-flex flex-column align-items-center gap-4">
                <IconsGen icon_name={"globedit"} />
                <button className="btn w-100">{_("panel.setcurrency")}</button>
              </div>
              <div className="col-md-3 col-12 flex-grow-1 d-flex flex-column align-items-center gap-4">
                <IconsGen icon_name={"globedit2"} />
                <button
                  className="btn w-100"
                  onClick={() => {
                    setUser();
                  }}
                >
                  {_("panel.setUser")}
                </button>
              </div>
              <div className="col-md-3 col-12 flex-grow-1 d-flex flex-column align-items-center gap-4">
                <IconsGen icon_name={"globedit3"} />
                <button className="btn w-100">{_("panel.verify")}</button>
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ProfilePreview;
