import React, { useEffect, useState } from "react";
import logo from "../../imgs/Logo_Ecombo 1.png";
import { Form, Button, Alert } from "react-bootstrap";
import {
  Link,
  useNavigate,
  useParams,
  NavLink,
  useLocation,
} from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import LangSelector from "../languages/LangSelector";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  Box,
  Progress,
  PasswordInput,
  Group,
  Text,
  Center,
} from "@mantine/core";
import { useInputState } from "@mantine/hooks";
import { IconCheck, IconX } from "@tabler/icons-react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { Radio } from "antd";
import AxiosController from "../authentication/AxiosController";
import Loader from "../loader/Loader";
import { type } from "@testing-library/user-event/dist/type";
import { Helmet } from "react-helmet";

function PasswordRequirement({ meets, label }) {
  return (
    <Text component="div" c={meets ? "teal" : "red"} mt={5} size="sm">
      <Center inline>
        {meets ? (
          <IconCheck size="0.9rem" stroke={1.5} />
        ) : (
          <IconX size="0.9rem" stroke={1.5} />
        )}
        <Box ml={7}>{label}</Box>
      </Center>
    </Text>
  );
}

const RegisterMarketer = () => {
  const { lang } = useParams();
  const Navigate = useNavigate();
  const [fullname, setFullname] = useState("");
  const [fullnameErr, setFullnameErr] = useState("");
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [roleErr, setRoleErr] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [age, setAge] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [dateOfBirthErr, setDateOfBirthErr] = useState("");
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [countriesErr, setCountriesErr] = useState();
  const [selectedCountryErr, setSelectedCountryErr] = useState();
  const [loader, setLoader] = useState(false);
  const [gender, setGender] = useState("");
  const [success, setSuccess] = useState("");
  const [genderErr, setGenderErr] = useState("");
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const [_] = useTranslation("global");
  const box_head_dir = lang === "en" ? "ltr" : "rtl";

  const register = () => {
    setLoader(true);
    let valid = true;
    let name_splitted = fullname.split(" ");
    if (fullname.length > 0 && name_splitted.length <= 1) {
      setLoader(false);
      setFullnameErr(_("registeration.fullnameerr"));
      valid = false;
    }
    if (strength != 100) {
      valid = false;
      setLoader(false);
    }

    if (valid) {
      AxiosController.post(`api/${lang}/auth/register`, {
        first_name: name_splitted[0],
        last_name: name_splitted.slice(1).join(" "),
        email,
        role: "affiliate",
        password: value,
        phone_number: phone,
        birthdate: dateOfBirth,
        sex: gender,
        country: selectedCountry.label,
        country_ISO: selectedCountry.value,
        referral_to: urlParams.get("userId") ?? null,
      })
        .then((res) => {
          Navigate(`/${lang}/auth/verify/${email}`);
          // setFullname("");
          // setEmail("");
          // setValue("");
          // setPhone("");
          // setGender("");

          // setFullnameErr("");
          // setEmailErr("");

          // setRoleErr("");
          // setPasswordErr("");
          // setPhoneErr("");
          // setDateOfBirthErr("");
          // setGenderErr("");
          // setCountriesErr("");
          // setSuccess(_("registeration.accountcreated"));
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          setFullnameErr(
            err.response.data.error.first_name
              ? err.response.data.error.first_name[0]
              : err.response.data.error.last_name
              ? err.response.data.error.last_name[0]
              : ""
          );
          setEmailErr(
            err.response.data.error.email
              ? err.response.data.error.email[0]
              : ""
          );

          setRoleErr(
            err.response.data.error.role ? err.response.data.error.role[0] : ""
          );
          setPasswordErr(
            err.response.data.error.password
              ? err.response.data.error.password[0]
              : ""
          );
          setPhoneErr(
            err.response.data.error.phone_number
              ? err.response.data.error.phone_number[0]
              : ""
          );
          setDateOfBirthErr(
            err.response.data.error.birthdate
              ? err.response.data.error.birthdate[0]
              : ""
          );
          setGenderErr(
            err.response.data.error.sex ? err.response.data.error.sex[0] : ""
          );
          setCountriesErr(
            err.response.data.error.country
              ? err.response.data.error.country[0]
              : err.response.data.error.country_ISO
              ? err.response.data.error.country_ISO[0]
              : ""
          );
          setLoader(false);
        });
    }
  };

  const handleApply = (event, picker) => {
    const startDate = picker.startDate.format("YYYY-MM-DD");
    setDateOfBirth(startDate);
  };

  const handleCallback = (event, picker) => {
    const startDate = picker._d.toISOString().split("T")[0];
    setDateOfBirth(startDate);
  };

  const requirements = [
    { re: /[0-9]/, label: _("registeration.includesNumber") },
    { re: /[a-z]/, label: _("registeration.includesLower") },
    { re: /[A-Z]/, label: _("registeration.includesUpper") },
    {
      re: /[$&+,:;=?@#|'<>.^*()%!-]/,
      label: _("registeration.includesSymbol"),
    },
  ];

  function getStrength(password) {
    let multiplier = password.length > 5 ? 0 : 1;

    requirements.forEach((requirement) => {
      if (!requirement.re.test(password)) {
        multiplier += 1;
      }
    });

    return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 0);
  }

  const [value, setValue] = useInputState("");
  const strength = getStrength(value);
  const checks = requirements.map((requirement, index) => (
    <PasswordRequirement
      key={index}
      label={requirement.label}
      meets={requirement.re.test(value)}
    />
  ));
  const bars = Array(4)
    .fill(0)
    .map((_, index) => (
      <Progress
        styles={{ section: { transitionDuration: "0ms" } }}
        value={
          value.length > 0 && index === 0
            ? 100
            : strength >= ((index + 1) / 4) * 100
            ? 100
            : 0
        }
        color={strength > 80 ? "teal" : strength > 50 ? "yellow" : "red"}
        key={index}
        size={4}
      />
    ));

  useEffect(() => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
        setSelectedCountry(data.userSelectValue);
      });
  }, []);
  return (
    <div
      className="inputs-data d-flex align-items-center px-5 justify-content-center flex-grow-1 position-relative"
      style={{ backgroundColor: "white", maxWidth: "100%" }}
      dir={box_head_dir}
    >
      {" "}
      <Helmet>
        <title>{_("registeration.marketer")}</title>
      </Helmet>
      <Loader active={loader} />
      <div className="data-entry-container" style={{ width: "100%" }}>
        <NavLink
          className="d-flex"
          style={{ paddingTop: "15px" }}
          to={`/${lang}/auth/login`}
        >
          <img
            onClick={() => Navigate("/")}
            style={{ cursor: "pointer" }}
            src={logo}
            width="230px"
            alt="logo"
          />
        </NavLink>
        <LangSelector lang={lang} />
        <div className="header d-flex justify-content-start">
          <h3 style={{ color: "rgba(243, 168, 55, 1)", fontSize: "1.6rem" }}>
            {_("registeration.welcoming2")}
          </h3>
        </div>
        <Form
          className="d-flex flex-column gap-2"
          style={{ direction: box_head_dir }}
          onSubmit={(e) => {
            e.preventDefault();
            register();
          }}
        >
          <Form.Group className="d-flex flex-column">
            <Form.Label> {_("registeration.fullname")}</Form.Label>
            <Form.Control
              type="text"
              className="p-2 m-0"
              placeholder={_("registeration.enter_fullname")}
              name="user_fullname"
              value={fullname}
              onChange={(e) => setFullname(e.target.value)}
            />
            {fullnameErr ? <Alert variant="danger">{fullnameErr}</Alert> : ""}
          </Form.Group>
          <Form.Group className="d-flex flex-column">
            <Form.Label> {_("registeration.email")}</Form.Label>
            <Form.Control
              type="email"
              className="p-2"
              placeholder={`${_("registeration.enter_email")}`}
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            {emailErr ? <Alert variant="danger">{emailErr}</Alert> : ""}
          </Form.Group>

          <Form.Group className="d-flex flex-column ">
            <PasswordInput
              value={value}
              onChange={setValue}
              placeholder="Your password"
              label={_("registeration.enter_password")}
              required
            />

            {value.length ? (
              <div>
                <Group gap={5} grow mt="xs" mb="md">
                  {bars}
                </Group>

                <PasswordRequirement
                  label={_("registeration.6chars")}
                  meets={value.length > 5}
                />
                {checks}
              </div>
            ) : (
              ""
            )}
            {passwordErr ? <Alert variant="danger">{passwordErr}</Alert> : ""}
          </Form.Group>
          <Form.Group className="d-flex flex-column ">
            <Form.Label>{_("registeration.country")}</Form.Label>
            <Select
              options={countries}
              value={selectedCountry}
              onChange={(selectedOption) => setSelectedCountry(selectedOption)}
              name="country"
            />
            {countriesErr ? <Alert variant="danger">{countriesErr}</Alert> : ""}
          </Form.Group>
          <Form.Group>
            <Form.Label> {_("registeration.phoneNumber")}</Form.Label>

            <div style={{ direction: "ltr" }}>
              <PhoneInput
                country={"sa"}
                preferredCountries={["sa"]}
                inputStyle={{ width: "100%" }}
                //  value={phone}
                onChange={(e) => setPhone(e)}
              />
            </div>
            {phoneErr ? <Alert variant="danger">{phoneErr}</Alert> : ""}
          </Form.Group>
          <Form.Group className="d-flex flex-column ">
            <Form.Label> {_("registeration.age")}</Form.Label>
            <small className="m-0">{age}</small>
            {/* <DateRangePicker
              initialSettings={{
                singleDatePicker: true,
                showDropdowns: true,
                startDate: "10/18/1984",
                minYear: 1901,
                maxYear: parseInt(moment().format("YYYY"), 10),
              }}
              onApply={handleApply}
              onCallback={handleCallback}
            >
              <input type="text" className="form-control col-4" />
            </DateRangePicker> */}
            <input
              type="date"
              min={"10/18/1984"}
              max={parseInt(moment().format("YYYY"), 10)}
              value={dateOfBirth}
              onChange={(e) => setDateOfBirth(e.target.value)}
              className="form-control col-4"
            />

            {dateOfBirthErr ? (
              <Alert variant="danger">{dateOfBirthErr}</Alert>
            ) : (
              ""
            )}
          </Form.Group>

          {/* <Form.Group className="d-flex flex-column">
            <Form.Label> {_("registeration.phoneNumber")}</Form.Label>
            <Form.Control
              type="text"
              className="p-2"
              placeholder={_("registeration.phoneNumber")}
              name="phoneNumber"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            {phoneErr ? <Alert variant="danger">{phoneErr}</Alert> : ""}
          </Form.Group> */}

          <Form.Group className="d-flex flex-column ">
            <Radio.Group
              onChange={(e) => {
                setGender(e.target.value);
              }}
              value={gender}
            >
              <Radio value={"male"}>{_("registeration.male")}</Radio>
              <Radio color="pink" value={"female"}>
                {_("registeration.female")}
              </Radio>
            </Radio.Group>
            {genderErr ? <Alert variant="danger">{genderErr}</Alert> : ""}
          </Form.Group>

          <input
            className="btn"
            type="submit"
            style={{ color: "white", backgroundColor: "rgba(89, 198, 201, 1)" }}
            value={_("panel.create")}
          />
          {success ? <Alert variant="success">{success}</Alert> : ""}

          <div
            className="d-flex justify-content-between"
            style={{ paddingBottom: "30px ", paddingTop: "10px " }}
          >
            <p
              style={{
                color: "rgba(214, 214, 214, 1)",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              <Link to="../login">{_("registeration.signin")}</Link>
            </p>
            <p
              style={{
                color: "rgba(214, 214, 214, 1)",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              <Link to="../register/seller">
                {_("registeration.signupSeller")}
              </Link>
            </p>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default RegisterMarketer;
