import "./Home.css";
import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Card, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { notification, Space } from "antd";
import AxiosController from "../../components/authentication/AxiosController";
import ProductsSlider from "../../components/home/ProductsSlider";
import IconsGen from "../../components/icons_generator/IconsGen";

const NewArrival = () => {
  const [newArrivalRow, handleNewArrivalRow] = useState([]);
  const [_] = useTranslation("global");
  const Navigate = useNavigate();
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");
  const [api] = notification.useNotification();

  const openNotification = (placement, status) => {
    api.success({
      style: { direction: lang == "ar" ? "rtl" : "ltr" },
      description: `${status}!`,
      placement,
    });
  };
  const addToCart = (prodcut_id) => {
    AxiosController.post(
      `api/${slug}/${lang}/add-to-cart/${prodcut_id}`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    )
      .then((res) => {
        document.getElementById("ecomboCart").click();
      })
      .catch((e) => {
        Navigate(`/${slug}/${lang}/product/${prodcut_id}`);
      });
  };
  const addToFav = (prodcut_id) => {
    AxiosController.post(
      `api/${slug}/${lang}/add-to-fav/${prodcut_id}`,
      {},
      { headers: { Authorization: `Bearer ${token}` } }
    )
      .then((res) => {
        openNotification(
          lang == "ar" ? "topRight" : "topLeft",
          res.data.success.fav_added
        );
      })
      .catch((e) => {
        Navigate(`/${slug}/${lang}/product/${prodcut_id}`);
      });
  };

  useEffect(() => {
    AxiosController.get(`api/${slug}/${lang}/product/new-arrivals/0`)
      .then((res) => {
        handleNewArrivalRow(res?.data?.data);
      })
      .catch((e) => {});
  }, [slug, lang, token]);

  return (
    <>
      {" "}
      <Row className="d-lg-flex d-none" dir={lang === "en" ? "rtl" : ""}>
        <div className="col-12 d-flex flex-row-reverse justify-content-between align-items-center">
          <h2
            style={{
              color: "rgba(23, 43, 77, 1)",
              marginTop: "50px",
              marginBottom: "50px",
            }}
            className="fs-3"
          >
            {_("home.newArrival")}
          </h2>
          <Link
            style={{
              color: "rgba(89, 198, 201, 1)",
              border: "1px solid rgba(89, 198, 201, 1)",
              background: "transparent",
              textDecoration: "none",
            }}
            className="p-2 rounded-3 cat-link"
            to={`/${slug}/${lang}/new-arrival/0`}
          >
            {_("home.seemore")}
          </Link>
        </div>
        <div className="col-lg-9">
          <div className="container">
            <div className="row">
              {newArrivalRow.slice(0, 6).map((product, rowIndex) => (
                <div className="col-lg-4" key={product.id}>
                  {product.price > 0 && (
                    <div className="p-2 d-flex">
                      <Card
                        className="pt-3 px-2 mr-2"
                        style={{
                          flexGrow: 1,
                          paddingRight: "100px",
                          minHeight: "100%",
                          position: "relative",
                        }}
                        dir="rtl"
                      >
                        {product["is_product_discount"] && (
                          <div className="best-offer">
                            <span className="right"></span>عروض
                            <span className="left"></span>
                          </div>
                        )}
                        <NavLink to={`/${slug}/${lang}/product/${product.id}`}>
                          <Card.Img
                            src={product.thumbnail}
                            className="card-img-top px-1"
                            style={{ borderRadius: "10px", height: "200px" }}
                          />
                        </NavLink>
                        <Card.Body className="px-1">
                          <Card.Title className="d-flex flex-column flex-sm-column flex-lg-row-reverse gap-2 justify-content-between align-items-center">
                            <div className="rating d-flex align-items-center justify-content-between flex-row-reverse">
                              <IconsGen icon_name="star" />
                              <p style={{ margin: 0 }}>4.5</p>
                            </div>
                            <p
                              className="title"
                              style={{ margin: 0, fontSize: "1.2rem" }}
                            >
                              {product.title
                                ? product.title.slice(0, 12) + "..."
                                : ""}
                            </p>
                          </Card.Title>
                          <Card.Text
                            style={{
                              color: "rgba(165, 165, 165, 1)",
                              fontSize: "14.5px",
                            }}
                          >
                            {}
                          </Card.Text>
                        </Card.Body>
                        <Card.Body
                          className={`px-1 py-1 d-flex ${
                            lang === "en" ? "flex-row-reverse" : ""
                          } justify-content-between gap-5`}
                        >
                          <p>{_("home.price")}</p>
                          <p>
                            {" "}
                            {product.price}
                            {slug === "sa"
                              ? _(`panel.ريال سعودي`)
                              : _(`panel.درهم امارتي`)}
                          </p>
                        </Card.Body>
                        <Card.Body
                          className={`px-1 py-1 pb-3 d-flex flex-column align-items-center flex-sm-column ${
                            lang === "en"
                              ? "flex-lg-row-reverse"
                              : "flex-lg-row"
                          } justify-content-between gap-2`}
                        >
                          <span
                            className="d-inline-block"
                            onClick={() => addToCart(product.id)}
                            id={product.id}
                          >
                            <button
                              style={{
                                border: "none",
                                padding: ".5rem .76rem",
                                borderRadius: "7px",
                              }}
                              className="addtocart"
                            >
                              {_("home.addtocart")}
                            </button>
                          </span>
                          <div
                            className={` ${
                              product.is_fav === "true" ? true : false
                            }`}
                            onClick={(e) => {
                              addToFav(product.id);
                              e.target.parentNode.classList.toggle("active");
                            }}
                          >
                            <IconsGen
                              icon_name={"heartCart"}
                              active={product.is_fav === "true" ? true : false}
                            />
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-lg-3" style={{ overflow: "hidden" }}>
          <img
            className="w-100 rounded shadow"
            style={{ height: "100%" }}
            src={"https://i.postimg.cc/90Ww25FS/new-Arrival.png"}
            alt="new arrival"
          />
        </div>
      </Row>
      <Row className="d-lg-none d-block  " dir={lang === "en" ? "rtl" : ""}>
        <Col className="d-flex flex-row-reverse justify-content-between align-items-center">
          <div className="col-12 d-flex flex-row-reverse justify-content-between align-items-center">
            <h2
              style={{
                color: "rgba(23, 43, 77, 1)",
                marginTop: "50px",
                marginBottom: "50px",
              }}
              className="fs-3"
            >
              {_("home.newArrival")}
            </h2>
          </div>
        </Col>
        <img
          className="w-100 rounded-5"
          style={{ height: "500px" }}
          src={"https://i.postimg.cc/90Ww25FS/new-Arrival.png"}
          alt="new arrival"
        />
        <ProductsSlider row={newArrivalRow} />
      </Row>
    </>
  );
};

export default NewArrival;
