import React, { useState } from "react";
import { Alert, Button, Container } from "react-bootstrap";
import Loader from "../../components/loader/Loader";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AxiosController from "../../components/authentication/AxiosController";
import { Helmet } from "react-helmet";

const Contact = () => {
  const { slug, lang } = useParams();
  const [loading, handleLoader] = useState(false);
  const [_] = useTranslation("global");
  const token = localStorage.getItem("token");
  const Navigate = useNavigate();
  const [subject, setSubject] = useState("");
  const [subjectErr, setSubjectErr] = useState("");
  const [message, setMessage] = useState("");
  const [messageErr, setMessageErr] = useState("");
  const [name, setName] = useState("");
  const [nameErr, setNameErr] = useState("");
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [success, setSuccess] = useState("");
  const placeMessage = () => {
    handleLoader(true);
    AxiosController.post(
      `api/${slug}/${lang}/contact-us`,
      {
        subject,
        message,
        name,
        email,
        phone,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        handleLoader(false);
        setSubject("");
        setMessage("");
        setSuccess(_("panel.success"));
        setSubjectErr("");
        setMessageErr("");
        setNameErr("");
        setEmailErr("");
        setPhoneErr("");
        setName("");
        setEmail("");
        setPhone("");
      })
      .catch((e) => {
        setSubjectErr(
          e.response.data.subject ? e.response.data.subject[0] : ""
        );
        setMessageErr(
          e.response.data.message ? e.response.data.message[0] : ""
        );
        setNameErr(e.response.data.message ? e.response.data.name[0] : "");
        setEmailErr(e.response.data.message ? e.response.data.email[0] : "");
        setPhoneErr(e.response.data.message ? e.response.data.phone[0] : "");
        handleLoader(false);
      });
  };
  return (
    <Container style={{ position: "relative" }}>
      <Loader active={loading} />
      <Helmet>
        <title> {_("home.contactus")}</title>
      </Helmet>
      <div className="add-product-container d-flex flex-column gap-2 my-3 px-4">
        <div className="add-header" dir={lang === "en" ? "ltr" : "rtl"}>
          <h2 className="mb-3" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("home.contactus")}
          </h2>
        </div>
        <div
          className="d-flex gap-5 flex-column-reverse flex-md-row shadow p-5"
          dir={lang === "en" ? "ltr" : "rtl"}
          style={{borderRadius:"20px"}}
        >
          <div
            className="order-right-container d-flex flex-column"
            style={{ flex: "7" }}
          >
            <div className="order-right-entry">
              <div className="add-body1 d-flex flex-column flex-md-row gap-4 mb-4">
                <div
                  className="right-side d-flex flex-column"
                  style={{ flex: "3" }}
                >
                  <div className="right-head">
                    <p>{_("home.verifydata")}</p>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      {_("panel.name")}
                    </label>
                    <input
                      type="text"
                      className="form-control py-2 px-3 egp"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={name}
                      onInput={(e) => setName(e.target.value)}
                    />
                    {nameErr ? (
                      <Alert variant={"danger"} className="mt-1">
                        {nameErr}
                      </Alert>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      {_("panel.email")}
                    </label>
                    <input
                      type="text"
                      className="form-control py-2 px-3 egp"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={email}
                      onInput={(e) => setEmail(e.target.value)}
                    />
                    {emailErr ? (
                      <Alert variant={"danger"} className="mt-1">
                        {emailErr}
                      </Alert>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      {_("panel.phone")}
                    </label>
                    <input
                      type="text"
                      className="form-control py-2 px-3 egp"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={phone}
                      onInput={(e) => setPhone(e.target.value)}
                    />
                    {phoneErr ? (
                      <Alert variant={"danger"} className="mt-1">
                        {phoneErr}
                      </Alert>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      {_("home.subject")}
                    </label>
                    <input
                      type="text"
                      className="form-control py-2 px-3 egp"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={subject}
                      onInput={(e) => setSubject(e.target.value)}
                    />
                    {subjectErr ? (
                      <Alert variant={"danger"} className="mt-1">
                        {subjectErr}
                      </Alert>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      {_("home.message")}
                    </label>
                    <textarea
                      className="form-control py-2 px-3 egp"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      rows="4"
                      value={message}
                      onInput={(e) => setMessage(e.target.value)}
                    ></textarea>
                    {messageErr ? (
                      <Alert variant={"danger"} className="mt-1">
                        {messageErr}
                      </Alert>
                    ) : (
                      ""
                    )}
                  </div>
                  {success ? <Alert variant={"success"}>{success}</Alert> : ""}
                  <div className="mb-3">
                    <Button
                      style={{
                        backgroundColor: "rgba(89, 198, 201, 1)",
                        color: "white",
                        border: "none",
                        width: "100%",
                      }}
                      className="fs-5"
                      onClick={() => {
                        setSuccess("");
                        placeMessage();
                      }}
                    >
                      {_("panel.send")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Contact;
