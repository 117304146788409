import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { BiMessageSquareDetail } from "react-icons/bi";

const ContactUs = ({ direction }) => {
  const [_] = useTranslation("global");
  const { slug, lang } = useParams();
  const [isSubNavVisible, setIsSubNavVisible] = useState(false);
  const role = localStorage.getItem("role");
  const employee_type = localStorage.getItem("employee_type");

  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes("/panel/contact-us/")) {
      setIsSubNavVisible(true);
    } else {
      setIsSubNavVisible(false);
    }
  }, [location.pathname]);
  const handleClick = () => {
    setIsSubNavVisible(!isSubNavVisible);
  };
  return (
    <div className="links-container d-flex flex-column">
      <div className="d-flex flex-column">
        <div
          className={`feature_link ${
            lang === "en" ? "feature_link_en" : ""
          } d-flex flex-row-reverse align-items-center gap-2 py-3        
             ${isSubNavVisible ? "active-link" : ""}`}
          dir={direction}
          onClick={handleClick}
        >
          <BiMessageSquareDetail className="icon" />
          <p>{_("home.contactus")}</p>
        </div>
        <ul
          className={`${
            isSubNavVisible ? "d-flex" : "d-none"
          } sub-nav  flex-column gap-2 px-5 pt-3`}
        >
          {role === "affiliate" && (
            <li>
              <NavLink
                to={`/${slug}/${lang}/panel/contact-us/affiliate-suggestion`}
              >
                <p className="d-inline ">{_("panel.AffiliateSuggestion")}</p>
              </NavLink>
            </li>
          )}
          <li>
            <NavLink
              to={`/${slug}/${lang}/panel/contact-us/affiliate-suggestion-admin`}
            >
              <p className="d-inline">{role === "affiliate"? _("panel.AffiliateSuggestionProduct"):_("panel.AffiliateSuggestions")}</p>
            </NavLink>
          </li>
          {(role === "admin" ||
            employee_type === "products employee" ||
            employee_type === "operation employee") && (
            <li>
              <NavLink to={`/${slug}/${lang}/panel/contact-us/messages`}>
                <p className="d-inline ">{_("home.contactus")}</p>
              </NavLink>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default ContactUs;
