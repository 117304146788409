import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const AutomaticLogout = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const lastActivity = localStorage.getItem("loginTime");
    const currentTime = new Date().getTime();
    const token = localStorage.getItem("token");

    // Allow access to reset password route even if the token is missing
    if (location.pathname.includes("auth/password/reset")) {
      return;
    }

    // If the token is missing or the session has expired, clear localStorage and redirect
    if (!token || (lastActivity && currentTime - parseInt(lastActivity, 10) > 43200000)) {
      localStorage.clear();
      navigate("/", { replace: true });
    }
  }, []);

  return <>{children}</>;
};

export default AutomaticLogout;
