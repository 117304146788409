import React, { useState, useRef } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { toast, ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import AxiosController from "../../../authentication/AxiosController";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import Confetti from "react-confetti";

export const AffiliateSuggestionForm = () => {
  const [_] = useTranslation("global");
  const { slug, lang } = useParams();
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);
  const formRef = useRef(null);

  const SuggestionSchema = Yup.object().shape({
    name: Yup.string().required(_("panel.required")),
    description: Yup.string().required(_("panel.required")),
    expected_quantity: Yup.number()
      .required(_("panel.required"))
      .min(1, _("panel.minQuantity")),
    image: Yup.mixed().required(_("panel.required")),
  });

  const initialValues = {
    name: "",
    description: "",
    expected_quantity: "",
    image: null,
  };

  const handleSubmit = async (values, { resetForm }) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("description", values.description);
    formData.append("expected_quantity", values.expected_quantity);
    formData.append("image", values.image);

    try {
      const response = await AxiosController.post(
        `api/${slug}/${lang}/products-suggestions`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success(_("panel.success"));
      resetForm();
      setImagePreview(null);
      setShowConfetti(true); // Trigger confetti
      setTimeout(() => setShowConfetti(false), 3000); // Stop confetti after 3 seconds
    } catch (error) {
      toast.error(_("panel.error"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container my-5">
      <Helmet>
        <title>{_("panel.AffiliateSuggestion")}</title>
      </Helmet>
      <h2 className="text-center mb-4">
        {_("panel.AffiliateSuggestionTitle")}
      </h2>
      <div className="card shadow-sm p-4" ref={formRef}>
        {showConfetti && formRef.current && (
          <Confetti
            width={formRef.current.offsetWidth}
            height={formRef.current.offsetHeight}
            recycle={false}
            numberOfPieces={150}
            gravity={0.3}
            style={{ position: "absolute", top: 0, left: 0 }}
          />
        )}
        <Formik
          initialValues={initialValues}
          validationSchema={SuggestionSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, errors, touched }) => (
            <Form>
              <div className="mb-3">
                <label className="form-label">{_("panel.productName2")}</label>
                <Field name="name" className="form-control" />
                {errors.name && touched.name && (
                  <div className="text-danger mt-1">{errors.name}</div>
                )}
              </div>
              <div className="mb-3">
                <label className="form-label">{_("panel.Description")}</label>
                <Field
                  name="description"
                  as="textarea"
                  className="form-control"
                  rows="3"
                />
                {errors.description && touched.description && (
                  <div className="text-danger mt-1">{errors.description}</div>
                )}
              </div>
              <div className="mb-3">
                <label className="form-label">{_("panel.quantity")}</label>
                <Field
                  name="expected_quantity"
                  type="number"
                  className="form-control"
                />
                {errors.expected_quantity && touched.expected_quantity && (
                  <div className="text-danger mt-1">
                    {errors.expected_quantity}
                  </div>
                )}
              </div>
              <div className="mb-4">
                <label className="form-label">{_("panel.image")}</label>
                <input
                  type="file"
                  className="form-control"
                  onChange={(event) => {
                    const file = event.currentTarget.files[0];
                    setFieldValue("image", file);
                    if (file) {
                      const reader = new FileReader();
                      reader.onload = () => setImagePreview(reader.result);
                      reader.readAsDataURL(file);
                    } else {
                      setImagePreview(null);
                    }
                  }}
                />
                {errors.image && touched.image && (
                  <div className="text-danger mt-1">{errors.image}</div>
                )}
                {imagePreview && (
                  <div className="mt-3 text-center">
                    <img
                      src={imagePreview}
                      alt="Preview"
                      style={{
                        maxHeight: "150px",
                        maxWidth: "100%",
                        borderRadius: "5px",
                      }}
                    />
                  </div>
                )}
              </div>
              <button
                className="btn w-100 border-0 text-white mt-3 mb-2"
                style={{ background: "rgba(89, 198, 201, 1)" }}
                type="submit"
                disabled={loading}
              >
                {loading ? _("panel.loading") : _("panel.save")}
              </button>
            </Form>
          )}
        </Formik>
      </div>
      <ToastContainer />
    </div>
  );
};
