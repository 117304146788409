import React, { useEffect, useState } from "react";
import { Alert, Button, Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Loader from "../../../loader/Loader";
import AxiosController from "../../../authentication/AxiosController";
import { Helmet } from "react-helmet";

const ContactUsWithId = () => {
  const { slug, lang } = useParams();
  const [loading, handleLoader] = useState(false);
  const [_] = useTranslation("global");
  const param = useParams();
  const token = localStorage.getItem("token");
  const Navigate = useNavigate();
  if (!token) {
    Navigate(`/ar/auth/login`);
  }
  const [data, setData] = useState();

  const placeMessage = () => {
    handleLoader(true);
     AxiosController.get(`api/${slug}/${lang}/contact-us/message/${param?.id}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
         setData(res?.data);
      })
      .catch((e) => {})
      .finally(() => {
        handleLoader(false);
      });
  };
  useEffect(() => {
    const getData = async () => {
      placeMessage();
    };
    getData();
  }, []);
  return (
    <Container style={{ position: "relative" }}>
      <Loader active={loading} />
      <Helmet >
   <title>{_("home.contactus")}</title> 
</Helmet>
      <div className="add-product-container d-flex flex-column gap-2 my-3 px-4">
        <div className="add-header" dir={lang === "en" ? "ltr" : "rtl"}>
          <h2 className="mb-3" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("home.contactus")}
          </h2>
        </div>
        <div
          className="d-flex gap-5 flex-column-reverse flex-md-row"
          dir={lang === "en" ? "ltr" : "rtl"}
        >
          <div
            className="order-right-container d-flex flex-column"
            style={{ flex: "7" }}
          >
            <div className="order-right-entry">
              <div className="add-body1 d-flex flex-column flex-md-row gap-4 mb-4">
                <div
                  className="right-side d-flex flex-column"
                  style={{ flex: "3" }}
                >
   
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      {_("panel.name")}
                    </label>
                    <input
                      type="text"
                      className="form-control py-2 px-3 egp"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={data?.name}
                      disabled
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      {_("panel.email")}
                    </label>
                    <input
                      type="text"
                      className="form-control py-2 px-3 egp"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={data?.email}
                      disabled
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      {_("panel.phone")}
                    </label>
                    <input
                      type="text"
                      className="form-control py-2 px-3 egp"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={data?.phone}
                      disabled
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      {_("home.subject")}
                    </label>
                    <input
                      type="text"
                      className="form-control py-2 px-3 egp"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={data?.subject}
                      disabled
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      {_("home.message")}
                    </label>
                    <textarea
                      className="form-control py-2 px-3 egp"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      rows="4"
                      value={data?.message}
                      disabled
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ContactUsWithId;
