import React, { useEffect, useState } from "react";
import "./style.css";
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AxiosController from "../../authentication/AxiosController";
import Loader from "../../loader/Loader";
import MyStoresTableRow from "./MyStoresTableRow";
import { Helmet } from "react-helmet";
import { Card, Col, Container, Row } from "react-bootstrap";
import ReactPlayer from "react-player";

const fetchStores = (slug, lang, token, handleOrdesData, handleLoader) => {
  AxiosController.get(`apiv2/${slug}/${lang}/builder/my-stores`, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      handleOrdesData(res.data);
      handleLoader(false);
    })
    .catch((e) => {
      handleLoader(false);
    });
};
const MyStores = () => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const token = localStorage.getItem("token");
  const [storesData, handleStoresData] = useState([]);
  const [page, handlePage] = useState(1);
  const [loading, handleLoader] = useState(false);
  const fetchStoresHandler = () => {
    fetchStores(slug, lang, token, handleStoresData, handleLoader);
  };
  useEffect(() => {
    handleLoader(true);
    fetchStoresHandler();
  }, [token, slug, lang, handleStoresData, page, handleLoader]);
  return (
    <div
      className="d-flex flex-column flex-lg-column my-2 gap-3 position-relative"
      style={{ width: "100%" }}
    >
      <Container  >
        <Row className="justify-content-center">
          <Col md={9}>
          <h1 className="text-center mb-4" style={{  color: 'rgba(89, 198, 201, 1)', fontWeight: 'bold', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}>
          طريقة عمل صفحة هبوط لاي منتج علي ايكومبو
          </h1>
            <Card className="shadow">
              <Card.Body>
                <ReactPlayer
                  url="https://www.youtube.com/watch?v=WQUFoAhlpjw"
                  width="100%"
                  controls={true}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container> 
      <Helmet>
        <title>{_("panel.myshop")}</title>
      </Helmet>
      <Loader active={loading} />
      <div
        className="tables-container d-flex flex-column px-2"
        style={{ position: "relative", flexGrow: 3 }}
      >
        <div
          className={`header d-flex ${
            lang === "en" ? "flex-row" : "flex-row"
          } gap-3 align-items-center justify-content-between`}
        >
          <h3 className="fs-1" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.myshop")}
          </h3>
          {storesData.length == 0 ? (
            <NavLink
              className="btn ecombo-btn"
              to={`/${slug}/${lang}/panel/my-shop/create-store`}
            >
              {_("panel.createStore")}
            </NavLink>
          ) : (
            ""
          )}
        </div>
        <div className="footer d-flex flex-column flex-grow-1">
          {storesData.length ? (
            <MyStoresTableRow
              stores_list={storesData}
              handleLoader={handleLoader}
              fetchStores={fetchStoresHandler}
            />
          ) : (
            <h3 className="text-center py-2 ecombo-secondary">
              {_("panel.notFound")}
            </h3>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyStores;
