import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useParams } from "react-router-dom";
import IconsGen from "../../../icons_generator/IconsGen";
import TableLabels from "./TableLabels";
import ConvertDate from "../../../convertdate/ConvertDate";
const ContactUsTable = ({
  statistics_list,
}) => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");


  return (
    <div
      className={`table-responsive my-3 d-flex ${
        lang === "en" ? "flex-row" : "flex-row-reversed"
      } align-items-center`}
    >
      <table className="table table-borderless" htmlFor="flexCheckDefault">
        <thead>
          <TableLabels />
        </thead>
        <tbody>
          {statistics_list?.map((statistic, index) => {
            return (
              <tr className="" key={index}>
                <td className="align-middle">
                  {ConvertDate(statistic["created_at"])} <br />
                </td>
                <td
                  style={{
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    verticalAlign: "middel",
                  }}
                >
                  <p> {statistic["subject"]}</p>
                </td>
                <td className="align-middle">{statistic["message"].slice(0, 10)}...</td>

                {statistic["mi_id"] ? (
                  <td className="align-middle">
                    {statistic["mi_first_name"] + " " + statistic["mi_last_name"]}{" "}
                    <br /> #{statistic["mi_id"]}
                  </td>
                ) : (
                  ""
                )}

                <td style={{ verticalAlign: "meddil" }}>
                  <NavLink
                    to={`/${slug}/${lang}/panel/contact-us/messages/${statistic["id"]}`}
                  >
                    {<IconsGen icon_name={"enter"} />}
                  </NavLink>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ContactUsTable;
