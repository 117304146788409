import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AxiosController from "../authentication/AxiosController";
import { notifyError, notifySuccess } from "../toastify/toastify";
import FunnelServerUrl from "../authentication/FunnelServerUrl";

const CreateLandingPage = ({ showModal, setShowModal, themeId }) => {
  const { slug, lang } = useParams();
  const [_] = useTranslation("global");
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [sku, setSku] = useState("");
  const [skuData, setSkuData] = useState("");
  const navigate = useNavigate();
  const [formValid, setFormValid] = useState(false);
  const [offers, setOffers] = useState([
    { price: 0, price_before: 0, quantity: 1 },
    { price: 0, price_before: 0, quantity: 2 },
    { price: 0, price_before: 0, quantity: 3 },
  ]);

  const requestSession = (uid) => {
    setLoading(true);
    AxiosController.get(
      `apiv2/${slug}/${lang}/builder/my-landpage-edit-session?uid=${uid}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        setLoading(false);
        window.open(
          `${FunnelServerUrl}?uid=${uid}&session=${res?.data}`,
          "_blank"
        );
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const showSku = (sku) => {
    setLoading(true);
    AxiosController.get(`apiv2/${slug}/${lang}/variants/sku/${sku}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setSkuData(res?.data);
        setSku(sku);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response && typeof error.response.data === "object") {
          const errorMessages = Object.entries(error.response.data)
            .map(
              ([key, value]) =>
                `${key}: ${Array.isArray(value) ? value.join(", ") : value}`
            )
            .join(", ");
          notifyError(errorMessages);
        } else {
          notifyError(
            Array.isArray(error.response.data)
              ? error.response.data.join(", ")
              : error.response.data
          );
        }
      });
  };

  const removeOffer = (index) => {
    setOffers(offers.filter((_, i) => i !== index));
  };

  const updateOffer = (index, field, value) => {
    const updatedOffers = [...offers];
 
    updatedOffers[index][field] = value; 
    if (field === "price" || field === "price_before") {
      const numericValue = parseFloat(value);
      if (!isNaN(numericValue)) {
        updatedOffers[index][field] = numericValue;
      }
    } else if (field === "quantity") {
      const numericValue = parseInt(value, 10);
      if (!isNaN(numericValue)) {
        updatedOffers[index][field] = numericValue;
      }
    }

    setOffers(updatedOffers); 
   
  };
  useEffect(() => { 
    const isValid = offers.every((offer) => {
      const minPrice =
        (skuData?.expected_shipping_cost || 0) +
        (skuData?.price || 0) * offer.quantity;
      return offer.price >= minPrice;
    });

    setFormValid(isValid);
  }, [offers, skuData]);
  const formik = useFormik({
    initialValues: {
      theme_id: themeId,
      user_custom_html: "",
      user_custom_css: "",
      store_visibility: "",
      productId: sku,
      favicon: null,
      store_name: skuData?.product_title || "",
      store_price: 0,
      expected_shipping_cost: skuData?.expected_shipping_cost || 0,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      store_visibility: Yup.string().required("Store visibility is required"),

      productId: Yup.string().required("Product ID is required"),

      store_name: Yup.string().required("Store name is required"),

      store_price: Yup.number()
        .required("Store price is required")
        .min(
          (skuData?.expected_shipping_cost || 0) + (skuData?.price || 0),
          `Store price must be at least ${(
            (skuData?.expected_shipping_cost || 0) + (skuData?.price || 0)
          ).toLocaleString()}`
        ),

      favicon: Yup.mixed().required(_("panel.favicon") + " is required"),
    }),

    onSubmit: (values) => {
      setLoading(true);

      const formData = new FormData();
      formData.append("theme_id", values.theme_id);
      formData.append("user_custom_html", values.user_custom_html);
      formData.append("user_custom_css", values.user_custom_css);
      formData.append("store_visibility", values.store_visibility);
      formData.append("productId", values.productId);
      formData.append("store_name", values.store_name);
      formData.append("store_price", values.store_price);
      formData.append("favicon", values.favicon);

      if (themeId !== 3) {
        offers.forEach((offer, index) => {
          formData.append(`offers[${index}][price]`, offer.price);
          formData.append(`offers[${index}][price_before]`, offer.price_before);
          formData.append(`offers[${index}][quantity]`, offer.quantity);
        });
      } else {
        if (offers.length > 0) {
          const offer = offers[0];
          formData.append(`offers[0][price]`, offer.price);
          formData.append(`offers[0][price_before]`, offer.price_before);
          formData.append(`offers[0][quantity]`, offer.quantity);
        }
      }

      AxiosController.post(
        `apiv2/${slug}/${lang}/builder/create-landpage`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
        .then((response) => {
          setLoading(false);
          navigate(`/${slug}/${lang}/panel/my-shop/my-landpages`);
          notifySuccess(_("panel.successMsg"));
          setShowModal(false);
          formik.resetForm();
          requestSession(response?.data?.uid);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response && typeof error.response.data === "object") {
            const errorMessages = Object.entries(error.response.data)
              .map(
                ([key, value]) =>
                  `${key}: ${Array.isArray(value) ? value.join(", ") : value}`
              )
              .join(", ");
            notifyError(errorMessages);
          } else {
            notifyError(
              Array.isArray(error.response.data)
                ? error.response.data.join(", ")
                : error.response.data
            );
          }
        });
    },
  });

  useEffect(() => {
    setSku("");
    if (showModal) {
      setOffers([
        { price: 0, price_before: 0, quantity: 1 },
        { price: 0, price_before: 0, quantity: 2 },
        { price: 0, price_before: 0, quantity: 3 },
      ]);
    }
    setSkuData("");
    setSku("");
  }, [showModal]);

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{_("panel.createLanding")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={formik.handleSubmit}> 
          <div className="mb-3 mt-3 ">
            <div className="d-flex gap-3">
              <input
                type="text"
                className={`form-control ${
                  formik.touched.productId && formik.errors.productId
                    ? "is-invalid"
                    : ""
                }`}
                id="productId"
                name="productId"
                placeholder={_("Sku")}
                value={formik.values.productId}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <Button
                disabled={loading}
                onClick={() => showSku(formik.values.productId)}
                style={{ backgroundColor: "rgba(89, 198, 201, 1)" }}
                className="btn border-0  "
              >
                عرض
              </Button>
            </div>

            {formik.touched.productId && formik.errors.productId && (
              <div className="invalid-feedback">{formik.errors.productId}</div>
            )}
          </div> 
          <div className="mb-3">
            <label htmlFor="store_name" className="form-label">
              {_("panel.product_name")}
            </label>
            <input
              type="text"
              className={`form-control ${
                formik.touched.store_name && formik.errors.store_name
                  ? "is-invalid"
                  : ""
              }`}
              id="store_name"
              placeholder={_("panel.product_name")}
              name="store_name"
              disabled={true}
              value={formik.values.store_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.store_name && formik.errors.store_name && (
              <div className="invalid-feedback">{formik.errors.store_name}</div>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="store_price" className="form-label">
              {_("panel.product_price")} (سعر المنتج يشمل سعر الشحن و القيمة
              التقريبية للشحن{" "}
              {Math.round(skuData?.expected_shipping_cost).toLocaleString()})
            </label>
            <input
              type="number"
              className={`form-control ${
                formik.touched.store_price && formik.errors.store_price
                  ? "is-invalid"
                  : ""
              }`}
              id="store_price"
              name="store_price"
              placeholder={_("panel.product_price")}
              value={formik.values.store_price}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <small className="p-2">
              {`Min: ${(
                (skuData?.expected_shipping_cost || 0) + (skuData?.price || 0)+1
              ).toLocaleString()}`}
            </small>
            {formik.touched.store_price && formik.errors.store_price && (
              <div className="invalid-feedback">
                {formik.errors.store_price}
              </div>
            )}
          </div>
          <div className="mb-3">
            <label htmlFor="store_visibility" className="form-label">
              {_("panel.store_visibility")}
            </label>
            <select
              className={`form-control ${
                formik.touched.store_visibility &&
                formik.errors.store_visibility
                  ? "is-invalid"
                  : ""
              }`}
              id="store_visibility"
              name="store_visibility"
              value={formik.values.store_visibility}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option disabled value="">
                {_("panel.store_visibility")}
              </option>
              <option value="1">{_("panel.yes")}</option>
              <option value="0">{_("panel.no")}</option>
            </select>
            {formik.touched.store_visibility &&
              formik.errors.store_visibility && (
                <div className="invalid-feedback">
                  {formik.errors.store_visibility}
                </div>
              )}
          </div>
          <div className="mb-3">
            <label htmlFor="favicon" className="form-label">
              {_("panel.landingImage")}
            </label>
            <input
              type="file"
              className={`form-control ${
                formik.touched.favicon && formik.errors.favicon
                  ? "is-invalid"
                  : ""
              }`}
              id="favicon"
              name="favicon"
              onChange={(event) =>
                formik.setFieldValue("favicon", event.currentTarget.files[0])
              }
            />
            {formik.touched.favicon && formik.errors.favicon && (
              <div className="invalid-feedback">{formik.errors.favicon}</div>
            )}
          </div>

          {skuData && (
            <img
              src={skuData.thumbnail}
              alt={skuData.name}
              className="img-thumbnail"
              style={{ width: "100%", height: "200px" }}
            />
          )}

          <div>
            <h5>{_("panel.offersLanding")}</h5>
            <table className="table">
              <thead>
                <tr>
                  <th>{_("panel.after_discount")}</th>
                  <th>{_("panel.before_discount")}</th>
                  <th>{_("panel.quantity")}</th>
                  {offers.length > 1 && <th>{_("panel.actions")}</th>}
                </tr>
              </thead>
              <tbody>
                {offers
                  .filter((_, index) => (themeId === 3 ? index === 0 : true))
                  .map((offer, index) => {
                    const minPrice =
                      ((skuData?.expected_shipping_cost || 0) +
                      (skuData?.price || 0) * offer.quantity)+1;
                    return (
                      <tr key={index}>
                        <td className="p-2">
                          <small>{`Min: ${minPrice.toLocaleString()}`}</small>{" "}
                          <input
                            type="number"
                            className="form-control"
                            placeholder={_("panel.after_discount")}
                            value={offer.price}
                            onChange={(e) =>
                              updateOffer(index, "price", e.target.value)
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control mt-4"
                            placeholder={_("panel.before_discount")}
                            value={offer.price_before}
                            onChange={(e) =>
                              updateOffer(index, "price_before", e.target.value)
                            }
                          />
                        </td>
                        <td className="p-2">
                          <input
                            type="number"
                            className="form-control  mt-4"
                            disabled={index === 0}
                            placeholder={_("panel.quantity")}
                            value={offer.quantity}
                            onChange={(e) =>
                              updateOffer(index, "quantity", e.target.value)
                            }
                          />
                        </td>
                        <td>
                          {offers.length > 1 && (
                            <button
                              type="button"
                              className="btn btn-danger mt-4"
                              disabled={index === 0}
                              onClick={() => removeOffer(index)}
                            >
                              {_("panel.delete")}
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <Button
            style={{ backgroundColor: "rgba(89, 198, 201, 1)" }}
            className="btn border-0 w-100 mt-3 mb-2 py-2"
            type="submit"
            disabled={loading}
          >
            {_("panel.create")}
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateLandingPage;
