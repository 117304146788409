import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { Formik, Field, Form as FormikForm } from "formik";
import * as Yup from "yup";
import AxiosController from "../authentication/AxiosController";
import { useParams } from "react-router-dom";
import { notifySuccess } from "../toastify/toastify";
import { useTranslation } from "react-i18next";

// Validation schema for form fields
const validationSchema = Yup.object({
  price: Yup.number().required("Price is required"),
  quantity: Yup.number().required("Quantity is required"),
  price_before: Yup.number(),
});

export const UpdateLandingPageOffers = ({
  show,
  handleClose,
  initialOfferData,
  fetchOffers,
}) => {
  const offerId = initialOfferData?.id;
  const users_landing_id = initialOfferData?.users_landing_id;
  const { lang, slug } = useParams();
  const [_] = useTranslation("global");

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title> {_("panel.update")}</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={{
          price: initialOfferData?.price || "",
          quantity: initialOfferData?.quantity || "",
          price_before: initialOfferData?.price_before || "",
          users_landing_id,
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          AxiosController.put(
            `apiv2/${slug}/${lang}/builder/offers/${offerId}`,
            values,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
            .then(() => {
              notifySuccess(_("panel.updateSuccessMsg"));

              fetchOffers();
              handleClose();
            })
            .catch((error) => {
              console.error("Error updating offer:", error);
            })
            .finally(() => setSubmitting(false));
        }}
      >
        {({ errors, touched, isSubmitting }) => (
          <FormikForm>
            <Modal.Body>
              <Form.Group controlId="price">
                <Form.Label>{_("panel.after_discount")}</Form.Label>
                <Field
                  name="price"
                  type="number"
                  className={`form-control ${
                    touched.price && errors.price ? "is-invalid" : ""
                  }`}
                />
                {touched.price && errors.price && (
                  <div className="invalid-feedback">{errors.price}</div>
                )}
              </Form.Group>
              <Form.Group controlId="quantity">
                <Form.Label>{_("panel.quantity")}</Form.Label>
                <Field
                  name="quantity"
                  type="number"
                  disabled={initialOfferData?.quantity === 1}
                  className={`form-control ${
                    touched.quantity && errors.quantity ? "is-invalid" : ""
                  }`}
                />
                {touched.quantity && errors.quantity && (
                  <div className="invalid-feedback">{errors.quantity}</div>
                )}
              </Form.Group>
              <Form.Group controlId="price_before">
                <Form.Label>{_("panel.before_discount")}</Form.Label>
                <Field
                  name="price_before"
                  type="number"
                  className="form-control"
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                {_("panel.cancel")}
              </Button>
              <Button variant="primary" type="submit" disabled={isSubmitting}>
                {_("panel.update")}
              </Button>
            </Modal.Footer>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};
