import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AxiosController from "../../../authentication/AxiosController";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Spinner, Card, Container, Row, Col } from "react-bootstrap";

export const AffiliateSuggestionAdminWithId = () => {
  const [_] = useTranslation("global");
  const { slug, lang, id } = useParams();
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [imageLoading, setImageLoading] = useState(true); // State for image loading

  const fetchSuggestion = async () => {
    setLoading(true);
    try {
      const response = await AxiosController.get(
        `api/${slug}/${lang}/products-suggestions/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      setData(response?.data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSuggestion();
  }, []);

  return (
    <Container fluid className="py-5">
      <Helmet>
        <title>{_("panel.AffiliateSuggestions")}</title>
      </Helmet>
      <h2
        className="text-center mb-5"
        style={{ color: "rgba(89, 198, 201, 1)" }}
      >
        {_("panel.AffiliateSuggestions")}
      </h2>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        data && (
          <Row className="justify-content-center">
            <Col md={8} lg={6}>
              <Card className="shadow-lg">
                {/* Image Loading Wrapper */}
                <div className="position-relative" style={{ height: "300px" }}>
                  {imageLoading && (
                    <div className="d-flex justify-content-center align-items-center h-100">
                      <Spinner animation="border" variant="primary" />
                    </div>
                  )}
                  <Card.Img
                    variant="top"
                    src={data.image_url}
                    alt={data.name}
                    style={{
                      height: "300px",
                      objectFit: "cover",
                      display: imageLoading ? "none" : "block",
                    }}
                    onLoad={() => setImageLoading(false)} // Set image loading to false once the image is loaded
                    onError={() => setImageLoading(false)} // Handle error state
                  />
                </div>
                <Card.Body>
                  <Card.Title className="fw-bold fs-3 text-center mb-4">
                    {data.name}
                  </Card.Title>
                  <Card.Text className="fs-5">
                    <strong>{_("panel.affiliate")} : </strong> {data.user_id}
                  </Card.Text>
                  <Card.Text className="fs-5">
                    <strong>{_("panel.Description")} : </strong>{" "}
                    {data.description || "N/A"}
                  </Card.Text>
                  <Card.Text className="fs-5">
                    <strong>{_("panel.quantity")} : </strong>{" "}
                    {data.expected_quantity}
                  </Card.Text>
                  <Card.Text className="fs-5">
                    <strong>{_("panel.time")} : </strong>{" "}
                    {new Date(data.updated_at).toLocaleDateString()}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )
      )}
    </Container>
  );
};
