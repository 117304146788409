import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AxiosController from "../../../../authentication/AxiosController";
import Loader from "../../../../loader/Loader";
import { Helmet } from "react-helmet";
import dateFormat from "dateformat";
import AllowedRoleAddAndUpdate from "../../../../modals/AllowedUserUpdate";
import IconsGen from "../../../../icons_generator/IconsGen";
import { notifyError, notifySuccess } from "../../../../toastify/toastify";

const AllowedRole = () => {
  const { lang, slug, currencyId } = useParams();
  const [_] = useTranslation("global");
  const token = localStorage.getItem("token");
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [loading, handleLoader] = useState(false);

  const fetchAllowedRole = () => {
    AxiosController.get(
      `api/${slug}/${lang}/allowd-currencies/get-private-users/${currencyId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        handleLoader(false);
        setData(res.data);
      })
      .catch((e) => {
        handleLoader(false);
      });
  };

  const deleteAllowedUser = (userId) => {
    handleLoader(true);

    AxiosController.delete(
      `api/${slug}/${lang}/allowd-currencies/delete-private-user/${currencyId}/${userId}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
      .then((res) => {
        notifySuccess(_("panel.delete_successful"));
        fetchAllowedRole();
        handleLoader(false);
      })
      .catch((error) => {
        handleLoader(false);
        if (error.response && typeof error.response.data === "object") {
          const errorMessages = Object.entries(error.response.data)
            .map(
              ([key, value]) =>
                `${key}: ${Array.isArray(value) ? value.join(", ") : value}`
            )
            .join(", ");
          notifyError(errorMessages);
        } else {
          notifyError(
            Array.isArray(error.response.data)
              ? error.response.data.join(", ")
              : error.response.data
          );
        }
      });
  };
  useEffect(() => {
    fetchAllowedRole();
  }, []); 

  return (
    <Container style={{ position: "relative" }}>
      <Helmet>
        <title>{_("panel.users")}</title>
      </Helmet>
      <Loader active={loading} />
      <AllowedRoleAddAndUpdate
        show={show}
        setShow={setShow}
        update={false}
        fetchAllowedRole={() => fetchAllowedRole()}
      />
      <div className="add-product-container d-flex flex-column gap-2 my-3 px-4">
        <div className="add-header" dir={lang === "en" ? "ltr" : "rtl"}>
          <h2 className="mb-3" style={{ color: "rgba(89, 198, 201, 1)" }}>
            {_("panel.users")}
          </h2>
        </div>
        <div
          className="d-flex gap-5 flex-column-reverse flex-md-row"
          dir={lang === "en" ? "ltr" : "rtl"}
        >
          <Button
            variant="primary"
            onClick={() => {
              setShow(true);
            }}
            style={{ background: "#59C6C9", color: "white", border: "none" }}
          >
            {_("panel.add")}
          </Button>
        </div>

        <div
          style={{ borderRadius: "40px" }}
          className={`table-responsive border my-3 d-flex ${
            lang === "en" ? "flex-row" : "flex-row-reversed"
          } align-items-center`}
        >
          <table className="table table-borderless" htmlFor="flexCheckDefault">
            <thead>
              <tr style={{ textAlign: "center" }}>
                <th>
                  <div className="mt-4">
                    <input
                      className="form-check-input"
                      style={{
                        border: "1px solid rgba(243, 168, 55, 1)",
                        transform: "translate(-0%, -50%)",
                      }}
                      type="checkbox"
                      value=""
                      id="flexCheckDefault"
                    />
                  </div>
                </th>
                <th>{_("panel.date")}</th>
                <th>{_("panel.user")}</th>
                <th>{_("panel.role")}</th>
                <th>{_("panel.delete")}</th>
              </tr>
            </thead>
            <tbody>
              {data.map((ele) => (
                <tr key={ele.id}>
                  <td>
                    <div>
                      <input
                        className="form-check-input"
                        style={{
                          border: "1px solid rgba(243, 168, 55, 1)",
                          transform: "translate(-0%, -50%)",
                        }}
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                    </div>
                  </td>
                  <td>
                    {dateFormat(ele["created_at"], "dd/mm/yyyy ")} <br />
                    {dateFormat(ele["created_at"], " h:MM TT")}
                  </td>
                  <td>
                    <span className="fw-bold"># {ele["id"]}</span>
                    <br />
                    {ele["first_name"]}
                    {ele["last_name"]}
                  </td>
                  <td>{ele["role"]}</td>
                  <td onClick={() => deleteAllowedUser(ele?.id)}>
                    <IconsGen icon_name={"delete"} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Container>
  );
};

export default AllowedRole;
