import React from "react";
import { useTranslation } from "react-i18next";
import {
  FaFacebookF,
  FaInstagram,
  FaYoutube,
  FaLinkedinIn,
  FaTiktok,
} from "react-icons/fa";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";

export default function Footer() {
  const [_] = useTranslation("global");
  const { lang, slug } = useParams();
  const navigate = useNavigate();
  const footerImg =
    "https://ecombo.sgp1.cdn.digitaloceanspaces.com/assets/footer-logo.png";
  const token = localStorage.getItem("token");

  const navLinks1 = [
    {
      key: "supplier-register",
      href: `/${lang}/auth/register/seller`,
    },
    {
      key: "marketer-register",

      href: `/${lang}/auth/register/marketer`,
    },
  ];
  const socialLinks = [
    {
      icon: <FaTiktok />,
      alt: "TikTok",
      href: "https://www.tiktok.com/@ecombo.ae",
    },
    {
      icon: <FaLinkedinIn />,
      alt: "LinkedIn",
      href: "https://www.linkedin.com/company/ecombonetwork/",
    },
    {
      icon: <FaYoutube />,
      alt: "YouTube",
      href: "https://www.youtube.com/@ecombo",
      delay: "200",
    },
    {
      icon: <FaInstagram />,
      alt: "Instagram",
      href: "https://www.instagram.com/ecombo.ae/",
    },
    {
      icon: <FaFacebookF />,
      alt: "Facebook",
      href: "https://www.facebook.com/Ecombo.ae/",
    },
  ];
  const navLinks2 = [
    {
      key: "returnAndExchangePolicy",

      href: `/${slug}/${lang}/return-and-exchange-policy`,
    },
    {
      key: "paymentAndDeliveryOptions",
      href: `/${slug}/${lang}/payment-and-delivery-options`,
    },
    {
      key: "privacyPolicy",

      href: `/${slug}/${lang}/privacy-policy`,
    },
    ...(token
      ? [
          {
            key: "contactus",

            href: `/${slug}/${lang}/contact-us`,
          },
        ]
      : []),
  ];
  return (
    <footer
      dir={lang === "en" ? "ltr" : "rtl"}
      className="mt-5 pt-5 text-white"
      style={{ background: "#172B4D" }}
    >
      <Container>
        <div className="mb-4">
          <img
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
            src={footerImg}
            width={150}
            height={44}
            alt="footer-logo"
          />
        </div>
        <Row className="gy-4">
          <Col md={6}>
            <p>{_("static_home.footer.description")}</p>
          </Col>
          <Col md={6} className="d-flex justify-content-around">
            <div>
              {navLinks1.map((link, index) => (
                <Link
                  key={index}
                  to={link.href}
                  rel="noopener noreferrer"
                  className="d-block mb-2 text-white  text-decoration-none"
                >
                  {_(`static_home.footer.navLinks1.${link.key}`)}
                </Link>
              ))}
            </div>
            <div>
              {navLinks2.map((navLink, idx) => (
                <Link
                  key={idx}
                  to={navLink.href}
                  className="d-block mb-2 text-white text-decoration-none"
                >
                  {_(`home.${navLink.key}`)}
                </Link>
              ))}
            </div>
          </Col>
        </Row>
        <Row className="mt-4 justify-content-center">
          {socialLinks.map((social, index) => (
            <Col xs="auto" key={index}>
              <Link to={social.href} target="_blank">
                <span className="fs-4 text-white"> {social.icon}</span>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
      <div className="border-top mt-4" style={{ background: "#172B4D" }}>
        <Container className="py-3">
          <Row className="justify-content-between">
            <Col xs="auto">
              <p className="mb-0 text-white">
                {_("static_home.footer.copy-right")}
              </p>
            </Col>
            <Col xs="auto">
              <p className="mb-0 text-white">
                {_("static_home.footer.all-rights")}
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
}
