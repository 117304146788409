/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import "./style.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AxiosController from "../../../authentication/AxiosController";
import Loader from "../../../loader/Loader";
import { Pagination } from "react-bootstrap";
import SettingsStatisticsTable from "./ContactUsTable";
import { Helmet } from "react-helmet";

const fetchOrders = (
  slug,
  lang,
  token,
  handleOrdesData,
  page,
  handleLoader
) => {
  AxiosController.get(`api/${slug}/${lang}/contact-us/${page}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
       handleOrdesData(res?.data);
      handleLoader(false);
    })
    .catch((e) => {});
};
const ContactUs = ({ customer_phone_order = "" }) => {
  const { slug, lang } = useParams();
  const location = useLocation();
  const Navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);

  const changeParam = (param, value) => {
    urlParams.set(param, value);
    Navigate(`?${urlParams.toString()}`);
  };

  const [_] = useTranslation("global");
  const token = localStorage.getItem("token");

  const [statistics, setStatistics] = useState();
  const [page, handlePage] = useState(urlParams.get("page") || 1);
  const [loading, handleLoader] = useState(false);
  const fetchingStatisticssHandler = useCallback(
    (show_all = false) => {
      handleLoader(true);
      const nextPage = parseInt(urlParams.get("page"), 10) || 1;
      handlePage(nextPage);
      fetchOrders(slug, lang, token, setStatistics, nextPage, handleLoader);
    },
    [lang, slug, token, urlParams]
  );

  useEffect(() => {
    handleLoader(true);
    fetchingStatisticssHandler();
  }, [token, slug, lang, location.search]);
  return (
    <div
      className="d-flex flex-column flex-lg-column my-2 gap-3 position-relative"
      style={{ width: "100%" }}
    ><Helmet>
    <title>{_("home.contactus")}</title> 
 </Helmet>
      <Loader active={loading} />
      <div
        className={`header d-flex ${
          lang === "en" ? "flex-row" : "flex-row"
        } gap-3 align-items-center justify-content-between`}
      >
        <h3 className="fs-2" style={{ color: "rgba(89, 198, 201, 1)" }}>
          {_("home.contactus")}
        </h3>
      </div>

      <div
        className="tables-container d-flex flex-column px-2"
        style={{ position: "relative", flexGrow: 3 }}
      >
        <div className="footer d-flex flex-column flex-grow-1">
          {statistics ? (
            <SettingsStatisticsTable statistics_list={statistics?.data} />
          ) : (
            ""
          )}
        </div>
        {statistics ? (
          <Pagination
            className="m-auto flex-wrap"
            dir={lang === "ar" ? "ltr" : ""}
          >
            <Pagination.First onClick={() => changeParam("page", 1)} />
            <Pagination.Prev
              onClick={() => (page - 1 > 0 ? changeParam("page", page - 1) : 1)}
            />
            {statistics["links"].map((element, index, arr) => {
              if (index === 0 || arr.length - 1 === index) {
                return "";
              } else {
                return (
                  <Pagination.Item
                    active={element["active"] ? true : false}
                    onClick={(e) =>
                      element["label"] !== "..."
                        ? changeParam("page", parseInt(element["label"]))
                        : ""
                    }
                  >
                    {element["label"]}
                  </Pagination.Item>
                );
              }
            })}
            <Pagination.Next onClick={() => changeParam("page", page + 1)} />
            <Pagination.Last
              onClick={() =>
                changeParam("page", statistics["last_page"])
              }
            />
          </Pagination>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ContactUs;
