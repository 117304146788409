 import React, { useContext, useEffect, useState } from "react";
import { Navbar, Container, Dropdown } from "react-bootstrap";
// imgs
import logo from "../../imgs/Logo_Ecombo 1.png";
// Icons_generator
import IconsGen from "../icons_generator/IconsGen";
import LangSelector from "../languages/LangSelector";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AxiosController from "../authentication/AxiosController";
import CurrencySelector from "../Currencies/CurrencySelector";
import CartOffCanvas from "./CartOffCanvas";
import SearchComponent from "./SearchComponent";
import PhoneSidebar from "./PhoneSidebar";
import { UserContext } from "../contexts/UserContext";

const get_cart = (lang, slug, token, handleCart, handleShow, handleLoader) => {
  handleLoader(true);
  handleShow();
  AxiosController.get(`api/${slug}/${lang}/get-cart`, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      handleCart(res.data);
      handleLoader(false);
    })
    .catch((e) => {
      handleLoader(false);
    });
};

const Mainbar = () => {
  const { setUserData } = useContext(UserContext);
  const { slug, lang } = useParams();
  const navigate = useNavigate();
  const [_] = useTranslation("global");
  const [userData, handleUserData] = useState({});
  const [userCurrencies, handleUserCurrencies] = useState([]);
  const [loading, handleLoader] = useState(false);
  const [cart, handleCart] = useState([]);

  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role") ?? "";
  const baseUrl = `/${slug}/${lang}/`;

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const Navigate = useNavigate();

  useEffect(() => {
    if (token) {
      AxiosController.get(`api/${slug}/${lang}/level-data`, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          handleUserData(res.data);
          setUserData(res?.data); 
        })
        .catch((e) => {});
      AxiosController.get(`api/${lang}/auth/user-currencies`, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          handleUserCurrencies(res.data);
        })
        .catch((e) => {});
    } else {
      AxiosController.get(`api/${lang}/auth/public-currencies`)
        .then((res) => {
          handleUserCurrencies(res.data);
        })
        .catch((e) => {});
      AxiosController.get(`api/${lang}/auth/publicdata`, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          handleUserData(res.data);
        })
        .catch((e) => {});
    }
  }, [token, lang, slug]);

  return (
    <Navbar
      expand="lg"
      style={{ boxShadow: "71px 4px 17px 0px rgba(112, 144, 176, 0.08)" }}
      dir={lang === "ar" ? "rtl" : "ltr"}
    >
      <div className="d-flex flex-column" style={{ width: "100%" }}>
        <Container className="d-flex justify-content-md-between justify-content-center  flex-column flex-md-row  align-items-center  ">
          <div className="d-flex justify-content-between align-items-center gap-3">
            <PhoneSidebar />
            <Link to={`/${slug}/${lang}`}>
              <img onClick={()=>navigate('/')} style={{cursor:"pointer"}} src={logo} width="140" alt="Logo" />
            </Link>
            <LangSelector slug={slug} lang={lang} />
            {/* <div className="d-flex gap-2 align-items-center">
              <div className="icons-container gap-3 d-flex">
              </div>
            </div> */}
          </div>

          {/* <p style={{ color: "rgb(243, 168, 55)" }}>{_("panel.beta")}</p> */}
          <div className="d-flex gap-4 align-items-center justify-content-between  d-md-flex">
            {token ? (
              <>
                <Link to="panel">
                  <IconsGen icon_name="chart" />
                </Link>
                <IconsGen icon_name="notification" />
              </>
            ) : (
              ""
            )}
            {role ? (
              ["AFFILIATE", "ADMIN", "EMPLOYEE", "FULFILLMENT"].includes(
                role.toUpperCase()
              ) ? (
                <div
                  onClick={() => {
                    get_cart(
                      lang,
                      slug,
                      token,
                      handleCart,
                      handleShow,
                      handleLoader
                    );
                  }}
                  id="ecomboCart"
                >
                  <IconsGen icon_name="bag" />
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}
            <CartOffCanvas
              show={show}
              handleClose={handleClose}
              cart={cart}
              handleCart={handleCart} 
              loading={loading}
            />
            <Dropdown>
              <Dropdown.Toggle
                style={{
                  backgroundColor: "transparent",
                  color: "rgba(23, 43, 77, 1)",
                  border: "none",
                }}
                id="dropdown-basic"
              >
                <IconsGen icon_name="user" />
                {token
                  ? userData.first_name + " " + userData.last_name
                  : _("panel.profile")}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {token ? (
                  <>
                    <Dropdown.Item
                      href={`/${slug}/${lang}/panel/profile`}
                      style={LinkStylesTerminators}
                    >
                      {_("navbar.profile")}
                    </Dropdown.Item>
                    <Dropdown.Item
                      href=""
                      onClick={() => {
                        localStorage.removeItem("token");
                        localStorage.removeItem("role");
                        localStorage.removeItem("employee_type");
                        localStorage.removeItem("ecombo_affiliate");
                        localStorage.removeItem("loginTime");
                        Navigate(`/${lang}/auth/login`);
                      }}
                    >
                      {_("navbar.logout")}
                    </Dropdown.Item>
                  </>
                ) : (
                  <Dropdown.Item
                    href={`/${lang}/auth/login`}
                    style={LinkStylesTerminators}
                  >
                    {_("panel.login")}
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Container>
        <Container>
          <div
            className="d-flex  flex-column flex-md-row justify-content-between py-4 align-items-center "
            style={{ width: "100%" }}
          >
<Dropdown className="d-flex  p-1 col-xl-2 col-md-3 col-12">
<Dropdown.Toggle
                style={{
                  backgroundColor: "transparent",
                  color: "rgba(89, 198, 201, 1)",
                  border: "1px solid rgba(89, 198, 201, 1)",
                  width: "100%",
                }}
                id="dropdown-basic"
              >
                <IconsGen icon_name="category" />
                {_("navbar.categories")}
              </Dropdown.Toggle>
              <Dropdown.Menu dir="rtl">
                {userData.categories
                  ? userData.categories.map((category) => {
                      return (
                        <Dropdown.Item
                          className="d-flex"
                          style={{
                            textAlign: lang === "en" ? "left" : "right",
                          }}
                        >
                          <Link
                            style={LinkStylesTerminators}
                            to={`/${slug}/${lang}/category/${category.id}`}
                            className={
                              "d-flex gap-1 " +
                              (lang == "ar" ? "flex-row-reverse" : "flex-row")
                            }
                          >
                            <p className="m-0">{category.name}</p>
                            <p className="m-0 " style={{ width: "24px" }}>
                              <img
                                style={{ width: "24px" }}
                                src={category.svg_icon_name}
                                alt=""
                              />
                            </p>
                          </Link>
                        </Dropdown.Item>
                      );
                    })
                  : ""}
              </Dropdown.Menu>
            </Dropdown>
            {role != "fulfillment" ? <SearchComponent /> : ""}

            <CurrencySelector userCurrencies={userCurrencies} />
          </div>
        </Container>
      </div>
    </Navbar>
  );
};
const LinkStylesTerminators = {
  color: "black",
  textDecoration: "none",
  width: "100%",
};

export default Mainbar;
